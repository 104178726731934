import React from 'react'
import { useTheme } from '@mui/material/styles'
import PropTypes from 'prop-types'
import { Box, Tooltip, IconButton, FormHelperText } from '@mui/material'
import {
  AddAPhoto as AddAPhotoIcon,
  HighlightOff as HighlightOffIcon,
  Videocam as VideocamIcon
} from '@mui/icons-material'
import Skeleton from '@mui/material/Skeleton'
import { useTranslation } from 'react-i18next'

import { ipfsConfig } from 'config'

function FileUploadPreview({
  onFileChange,
  file,
  spacing = false,
  loading = false,
  allowDelete = false,
  onDelete = () => {},
  name = 'FileUploadPreview',
  allowIpCameras = false,
  onIpCameraPress = () => {},
  error
}) {
  const { t } = useTranslation()
  const theme = useTheme()

  return (
    <>
      <Box
        display='flex'
        flexDirection='row'
        justifyContent={file ? 'space-around' : 'center'}
        alignItems='center'
        flex={1}
        my={1}
      >
        {file && !loading && (
          <Box
            display='flex'
            alignItems='center'
            justifyContent='center'
            mb={spacing ? 2 : 0}
            width='80%'
            height={100}
          >
            <img
              src={`${ipfsConfig.url}${file}`}
              alt={file}
              width='50%'
              height='100%'
            />
          </Box>
        )}

        {loading && <Skeleton width='80%' height={100} />}

        <input
          id={name}
          type='file'
          accept='image/*,text/plain'
          onChange={onFileChange}
          style={{ display: 'none' }}
        />
        <Box display='flex' alignItems='center' justifyContent='center' px={2}>
          <label htmlFor={name}>
            <Tooltip title={t('takePhoto')}>
              <AddAPhotoIcon sx={{ mr: 2 }} />
            </Tooltip>
          </label>

          {allowIpCameras && (
            <Tooltip title={t('getPhoto')}>
              <IconButton onClick={onIpCameraPress} size='large'>
                <VideocamIcon sx={{ color: theme.palette.common.black }} />
              </IconButton>
            </Tooltip>
          )}

          {allowDelete && (
            <Tooltip title={t('delete')}>
              <IconButton onClick={onDelete} size='large'>
                <HighlightOffIcon sx={{ color: theme.palette.common.black }} />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      </Box>
      {error ? (
        <FormHelperText error={true} variant='outlined' margin={undefined}>
          {error}
        </FormHelperText>
      ) : null}
    </>
  )
}

FileUploadPreview.propTypes = {
  onFileChange: PropTypes.func.isRequired,
  file: PropTypes.string,
  spacing: PropTypes.bool,
  name: PropTypes.string,
  loading: PropTypes.bool,
  allowDelete: PropTypes.bool,
  onDelete: PropTypes.func,
  allowIpCameras: PropTypes.bool,
  onIpCameraPress: PropTypes.func,
  error: PropTypes.string
}

export default FileUploadPreview
