import React from 'react'
import PropTypes from 'prop-types'
import { Box, Tooltip, IconButton, FormHelperText } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { Videocam as VideocamIcon } from '@mui/icons-material'
import Skeleton from '@mui/material/Skeleton'
import { useTranslation } from 'react-i18next'

import { BaseButton, FilePreview } from 'components'

function FileUpload({
  onChange,
  value,
  label = 'takePhoto',
  name = 'FileUpload',
  loading = false,
  spacing = false,
  allowIpCameras = false,
  onIpCameraPress = () => {},
  error
}) {
  const { t } = useTranslation()
  const theme = useTheme()

  return (
    <>
      <Box
        display='flex'
        flexDirection='row'
        justifyContent='space-between'
        alignItems='center'
        width='100%'
        border={error ? 1 : undefined}
        borderColor={error ? theme.palette.error.main : undefined}
        my={1}
      >
        <>
          <input
            style={{ display: 'none' }}
            id={name}
            type='file'
            accept='image/*,text/plain'
            onChange={onChange}
          />
          <label htmlFor={name} style={{ flex: 1 }}>
            <BaseButton
              component='span'
              style={{
                border: error ? 1 : undefined,
                borderColor: error ? theme.palette.error.main : undefined
              }}
            >
              {t(label)}
            </BaseButton>
          </label>
        </>

        {allowIpCameras && (
          <Tooltip title={t('getPhoto')}>
            <IconButton onClick={onIpCameraPress} size='large'>
              <VideocamIcon sx={{ color: theme.palette.common.black }} />
            </IconButton>
          </Tooltip>
        )}
      </Box>
      {value && !loading && <FilePreview value={value} spacing={spacing} />}
      {loading && <Skeleton height={150} />}
      {error ? (
        <FormHelperText error={true} variant='outlined' margin={undefined}>
          {error}
        </FormHelperText>
      ) : null}
    </>
  )
}

FileUpload.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  spacing: PropTypes.bool,
  loading: PropTypes.bool,
  allowIpCameras: PropTypes.bool,
  onIpCameraPress: PropTypes.func,
  error: PropTypes.string
}

export default FileUpload
